<template>
  <div class="lottery">
    <section class="guide" v-if="step === 0">
      <h1>
        <img src="https://img.xinshu.me/upload/a4bd5298b66543dea626a8f1da3070b2"
             alt="翻牌领折扣，最低至1折" draggable="false"
             style="max-width: 66%;">
      </h1>
      <p>
        <img src="https://img.xinshu.me/upload/64c04a0ee1e145cdb12b2ab26dab6377"
             style="width: 80%" draggable="false">
      </p>
      <div class="rules">
        <div class="title">活动规则</div>
        <ol>
          <li>每位用户初始有三次翻牌机会</li>
          <li>次数用完后，可邀请好友帮忙助力。每邀请一位好友助力，可获得一次翻牌机会</li>
          <li>邀请好友助力也可以增加中奖几率，助力越多，中大奖几率就越高</li>
          <li>中奖后奖品会直接发放到用户优惠券</li>
          <li>活动时间：2020年10月28日-2020年11月8日</li>
        </ol>
      </div>
      <b-btn block
             class="game-start"
             variant="primary"
             pill
             @click="startGame"
             :disabled="starting || hasEnded">
        <fa icon="spinner" spin v-if="starting"/>
        <span v-else-if="hasEnded">活动已结束</span>
        <span v-else>开始游戏</span>
      </b-btn>
    </section>

    <section v-if="step === 1">
      <b-btn class="reward" to="/my/wallet" variant="primary" size="sm">
        <fa icon="gift" fad/>
        我的奖品
      </b-btn>

      <h1>
        <img src="https://img.xinshu.me/upload/1eeb596b9a5f46ecb06849558680844b"
             alt="翻牌领折扣" draggable="false"
             style="max-width: 66%;">
      </h1>

      <div class="mx-auto" style="margin-bottom: -1.5%; margin-top: -3%;">
        <img src="https://img.xinshu.me/upload/64c04a0ee1e145cdb12b2ab26dab6377"
             style="width: 60%" draggable="false">
      </div>

      <div class="box">
        <div class="title-color">
          <avatar :src="user.avatar" size="1.5em"/>
          <ske width="7em" v-if="starting"/>
          <span v-else-if="remain">剩余翻牌次数 {{remain}} 次</span>
          <span v-else>翻牌次数已耗尽</span>
        </div>
        <div class="game-zone" v-if="starting">
          <grid cols="3" @click.stop>
            <grid-item v-for="i in prizes" :key="i">
              <ske block type="square"/>
            </grid-item>
          </grid>
        </div>
        <div class="game-zone" v-else>
          <div class="mask" v-if="remain <= 0">
            <div>
              <div class="title">邀请好友助力</div>
              <p>
                每次邀请1位好友助力，可额外获得1次翻卡机会。
                <br>
                同时好友也可以参与活动获得折扣，邀请越多，获得低折扣的概率越大。
              </p>
              <b-btn block variant="primary" pill @click="sharing = true">
                邀请好友助力
              </b-btn>
            </div>
          </div>
          <grid cols="3" @click.stop>
            <grid-item v-for="(i, index) in prizes" :key="i">
              <div class="item"
                   @click.stop="turn(index)"
                   :class="{active: (i === active) || finished}">
                <div class="front">
                  <img src="https://img.xinshu.me/upload/61dda5bbef594039b4edd6e0baa8eb37"
                       alt="正面" draggable="false">
                </div>
                <div class="back">
                  <img :src="prizeImages[i]" :alt="i === 10 ? '未中奖' : i + '折'" draggable="false">
                </div>
              </div>
            </grid-item>
          </grid>
        </div>
      </div>

      <div class="box" v-if="remain > 0">
        <div class="title">邀请好友助力</div>
        <p>
          每次邀请1位好友助力，可额外获得1次翻卡机会。
          <br>
          同时好友也可以参与活动获得折扣，邀请越多，获得低折扣的概率越大。
        </p>
        <b-btn block variant="primary" pill @click="sharing = true">
          邀请好友助力
        </b-btn>
      </div>

      <div class="box">
        <div class="title">为您助力的好友</div>
        <grid cols="4" v-if="starting">
          <grid-item v-for="i in 3" :key="i">
            <ske block type="square"/>
            <div class="small mt-1">
              <ske width="3em"></ske>
            </div>
          </grid-item>
        </grid>
        <grid cols="4" sm="6" v-else-if="friends.length">
          <grid-item v-for="item in friends" :key="item.openid">
            <avatar :src="item.avatar"/>
            <div class="small mt-1 text-overflow">{{item.nickname}}</div>
          </grid-item>
        </grid>
        <empty v-else icon="users">暂无助力好友哦</empty>
      </div>
    </section>

    <section v-if="step === -1">
      <b-card>
        <emotion value="cry" size="200px"/>
        <h4 class="mb-3">没有相关的活动哦</h4>
        <b-btn @click="startGame" pill class="game-start" variant="primary" :disabled="starting">
          重新开始游戏
        </b-btn>
      </b-card>
    </section>

    <transition name="fade">
      <div class="share" v-if="sharing" @click="sharing = false">
        <img src="https://img.xinshu.me/upload/425eaeb8e79348909c0d938338e52a4a"
             alt="" draggable="false">
      </div>
    </transition>

    <popup card v-if="helpPopupActive" keep>
      <h4 class="title-color">助力成功</h4>
      <p>
        <img src="https://img.xinshu.me/upload/39b5ed2c18ae4a71822f597ccc8fd4e3"
             style="height: 80px;" draggable="false"/>
      </p>
      <p>感谢您的助力，点击下面的按钮自己玩</p>
      <b-btn variant="primary" pill block href="/promotions/lottery-2020" target="_top">
        我也要玩
      </b-btn>
    </popup>

    <popup card v-if="popupActive" @close="popupActive = false" keep>
      <h4 class="title-color">{{active === 10 ? '很遗憾，没有中奖哦' : '恭喜你获得'}}</h4>
      <p>
        <img :src="prizeImages[active]" style="width: 86px;height: 86px;" draggable="false"/>
      </p>
      <p v-if="active === 10">
        再试一次吧
      </p>
      <p v-else>
        你可以在「我的奖品」中查看
        <br>
        邀请好友助力可增加翻牌次数
      </p>
      <b-btn block pill v-if="remain" variant="primary" @click="reset()">再抽一次</b-btn>
      <template v-else>
        <b-btn block pill variant="primary" @click="popupActive = false; sharing = true">
          邀请好友，再抽一次
        </b-btn>
        <b-btn variant="link" block @click="popupActive = false">关闭</b-btn>
      </template>
    </popup>
  </div>
</template>

<script>
import { chain, round, shuffle } from 'lodash'
import lean from '@/models/leancloud'
import { configShare } from '@/modules/wechat'

export default {
  name: 'lottery2020',
  title: '玩翻牌领折扣，最低一折起！',
  data() {
    return {
      // hasEnded: new Date() >= new Date('2020-11-09 00:00:00+0800'),
      hasEnded: false,
      active: null,
      finished: false,
      popupActive: false,
      helpPopupActive: false,
      sharing: false,
      starting: false,
      objectId: this.$route.params.objectId,
      step: 0,
      prizes: [1, 2, 3, 5, 6, 7, 8, 9, 10],
      gameData: null,
      initialTimes: 3,
      expectTimes: 10,
      prizeCoupons: {
        1: 'NZJD46',
        2: 'UD35OJ',
        3: 'FRB4WX',
        5: 'VR2ISZ',
        6: 'TKVPNW',
        7: 'ESNGP6',
        8: 'YK0BQT',
        9: 'UNEV54'
      },
      prizeImages: {
        1: 'https://img.xinshu.me/upload/afb48f088080414b9863c1561b0f83e9',
        2: 'https://img.xinshu.me/upload/9b8ebb17101f4d0f9eb8560dbc558815',
        3: 'https://img.xinshu.me/upload/182d3bdc9837438586513e598989f9e0',
        5: 'https://img.xinshu.me/upload/8c1eb856c3e7489989a22ff9d1487762',
        6: 'https://img.xinshu.me/upload/02b99181384f49f58f7676cd74686763',
        7: 'https://img.xinshu.me/upload/82cda06a168a4fdf9bfb3502b2fddce7',
        8: 'https://img.xinshu.me/upload/2481972d2adf4abc974719bf6ab913d8',
        9: 'https://img.xinshu.me/upload/93b6dcecaec441fbb58c2372521d9177',
        10: 'https://img.xinshu.me/upload/1f174c8f7db5444aa341ad7b8ac98b24'
      },
      oddsList: [
        [10, 5],
        [9, 30],
        [8, 26],
        [7, 20],
        [6, 8],
        [5, 5],
        [3, 3],
        [2, 2],
        [1, 1]
      ],
      finalOddsList: [
        [10, 2],
        [9, 1],
        [8, 3],
        [7, 6],
        [6, 8],
        [5, 10],
        [3, 26],
        [2, 24],
        [1, 20]
      ]
    }
  },
  computed: {
    times: {
      get() {
        return this.gameData?.times || 0
      },
      set(val) {
        this.gameData.times = val
      }
    },
    friends() {
      return this.gameData?.friends || []
    },
    remain() {
      return this.initialTimes + this.friends.length - this.times
    }
  },
  async created() {
    if (this.objectId) {
      this.step = 1
      this.starting = true
      await this.initGameData()
      setTimeout(() => {
        this.starting = false
      }, 500)
      if (this.gameData.openid !== this.user.openid) {
        await this.doHelp()
        this.helpPopupActive = true
      }
    }

    configShare({
      title: '玩翻牌领折扣！最低一折起，薅羊毛就趁现在！',
      desc: '邀请好友助力享更多折扣',
      link: 'https://weixinshu.com/promotions/lottery-2020/' + (this.objectId || ''),
      imgUrl: 'https://img.xinshu.me/upload/a1725ee7b5904dc49abe576a0e281075'
    })
  },
  methods: {
    async startGame() {
      this.starting = true
      const openid = this.user.openid
      let [result] = await lean.get('/classes/Lottery2020', {
        params: {
          where: JSON.stringify({openid}),
          limit: 1
        }
      })
      if (!result && openid === this.user.openid) {
        result = await lean.post('/classes/Lottery2020', {openid}, {
          params: {fetchWhenSave: true}
        })
      }
      if (!result) {
        this.$alert.error('游戏初始化失败，请重试')
        return
      }
      location.href = '/promotions/lottery-2020/' + result.objectId
    },
    async initGameData() {
      this.gameData = await lean.get('/classes/Lottery2020/' + this.objectId)
      this.step = this.objectId ? 1 : -1
    },
    async doHelp() {
      const {friends} = await lean.put('/classes/Lottery2020/' + this.objectId, {
        friends: {
          __op: 'AddUnique',
          objects: [
            {
              avatar: this.user.avatar,
              nickname: this.user.nickname,
              openid: this.user.openid
            }
          ]
        }
      }, {
        params: {
          fetchWhenSave: true
        }
      })

      this.gameData.friends = friends
    },
    test() {
      const totalCount = 100000
      const results = {}
      const prizes = this.prizes.sort((a, b) => a - b)
      for (const prize of prizes) {
        results[prize] = 0
      }
      for (let i = 0; i < totalCount; i++) {
        const prize = this.calcPrize()
        results[prize]++
      }
      console.clear()
      console.log('当前模拟助力次数 %d', this.times)
      for (const prize of prizes) {
        const count = chain(results).map((v, k) => k <= prize ? v : 0).sum().value()
        console.log(
          '%d折概率: %s (%s)',
          prize,
          round(results[prize] / totalCount * 100, 2) + '%',
          round(count / totalCount * 100, 2) + '%'
        )
      }
    },
    reset() {
      this.popupActive = false
      this.finished = false
      this.active = null
    },
    turn(index) {
      if (this.active) {
        return
      }

      const prize = this.calcPrize()
      while (this.prizes[index] !== prize) {
        this.prizes = shuffle(this.prizes)
      }

      this.finished = false
      setTimeout(() => {
        this.active = prize
      })

      setTimeout(() => {
        this.finished = true
      }, 1000)

      setTimeout(() => {
        this.popupActive = true
        this.times++

        lean.put('/classes/Lottery2020/' + this.objectId, {
          times: {
            __op: 'Increment',
            amount: 1
          }
        }, {
          params: {
            fetchWhenSave: true
          }
        }).then(result => {
          this.times = result.times
          if (this.remain > 0) {
            const couponNo = this.prizeCoupons[prize]
            if (couponNo) {
              this.$req.get(`/api/user/activity_coupons/${couponNo}`)
            }
          }
        })
      }, 2000)
    },
    calcPrize() {
      const times = this.times
      const balls = []
      for (let i = 0; i < this.oddsList.length; i++) {
        const [discount, odds] = this.oddsList[i]
        const [, finalOdds] = this.finalOddsList[i]
        let calculatedOdds = odds
        if (times > this.initialTimes) {
          calculatedOdds += (times - this.initialTimes) /
            (this.expectTimes - this.initialTimes) *
            (finalOdds - odds)
        }
        for (let i = 0; i < calculatedOdds; i++) {
          balls.push(discount)
        }
      }
      let result = 0
      for (let i = -1; i < this.times; i++) {
        result = balls[Math.floor(Math.random() * balls.length)]
      }
      return result
    }
  }
}
</script>

<style lang="scss">
[data-page="lottery2020"] {
  main {
    padding-bottom: 0 !important;
    background-color: #fff1e6;
  }
}
</style>

<style scoped lang="scss">
.title-color {
  color: #70534b;
}

h4.title-color {
  margin-bottom: 1em;
}

.lottery {
  max-width: 560px;
  text-align: center;
  min-height: 100vh;
  background-color: #fff1e6;
  background-image: url(https://img.xinshu.me/upload/94f01f54b1f64100ae218f0284eab405);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
  user-select: none;
  padding: 0;

  section {
    height: 100%;
    padding: $grid-gutter-width;
    min-height: 100vh;
    position: relative;
  }

  section.guide {
    display: flex;
    flex-direction: column;

    .rules {
      flex: 1;
    }
  }

  .reward {
    position: absolute;
    right: 0;
    top: 2rem;
    border-radius: 100px 0 0 100px;
    padding-right: .5em;
  }

  .box {
    position: relative;
    border-radius: 1rem;
    border: 2px solid #ffd0ab;
    padding: $grid-gutter-width;
    background-color: #fff;
    margin-bottom: 2rem;
    overflow: hidden;
    perspective: 3000px;
  }

  .title {
    font-size: 1.25rem;
    font-weight: bold;
    color: $primary;
    margin-bottom: .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;

    .content {
      padding: 0 1em;
      line-height: 1;
    }

    &:before, &:after {
      content: '';
      display: block;
      flex: 1;
      height: 2px;
      width: 100%;
      max-width: 10rem;
    }

    &:before {
      margin-right: 1rem;
      background-image: linear-gradient(to right, transparentize($primary, 1), $primary);
    }

    &:after {
      margin-left: 1rem;
      background-image: linear-gradient(to left, transparentize($primary, 1), $primary);
    }
  }

  .game-zone {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgba(255, 255, 255, .7);
    padding: $grid-gutter-width;

    & + .grid {
      filter: blur(10px);
      pointer-events: none;
    }
  }

  .item {
    transition: transform .3s;
    transform-style: preserve-3d;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &.active {
      transform: rotateY(-180deg);
    }
  }

  .front, .back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .back {
    transform: rotateY(180deg);
    z-index: 2;
  }

  .game-start {
    margin-left: auto;
    margin-right: auto;
    width: 12rem;
    border: 0;
    border-bottom: 5px solid lighten($primary, 10%);
  }

  ol {
    margin-top: 1em;
    margin-bottom: 1em;
    color: #70534b;
    text-align: left;
    max-width: 25em;
    margin-left: auto;
    margin-right: auto;

    li {
      padding: .25rem 0;
    }
  }

  .share {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    z-index: 30;
    padding: 0 1rem;

    img {
      margin-left: auto;
      margin-right: auto;
      max-width: 560px;
      width: 100%;
      display: block;
    }
  }
}
</style>
